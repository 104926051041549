<template>
  <a-card :bordered="false" class="card-area">
    <div class="search">
      <!-- 搜索区域 -->
      <div class="items" style="flex: none">
        <div class="one-item" style="width: 200px">
          <a-input
              class="input"
              v-model="queryParams.username"
              placeholder="用户名称"
              allowClear
          />
        </div>
        <div class="one-item">
          <a-select
              class="input"
              v-model="queryParams.requestType"
              @change="search(1)"
              allowClear
              placeholder="支付类型"
          >
            <a-select-option v-for="r in dict['payRequestType']" :key="r.value.toString()">{{ r.label }}
            </a-select-option>
          </a-select>
        </div>
        <div class="one-item">
          <a-select
              class="input"
              v-model="queryParams.status"
              @change="search(1)"
              allowClear
              placeholder="支付状态"
          >
            <a-select-option v-for="r in dict['payRequestStatus']" :key="r.value.toString()">{{ r.label }}
            </a-select-option>
          </a-select>
        </div>

      </div>
      <div class="operator">
        <a-button type="primary" @click="search(1)">查询</a-button>
        <a-button style="margin-left: 8px" @click="reset">重置</a-button>
      </div>
    </div>
    <a-table
        ref="TableInfo"
        size="small"
        :bordered="true"
        :columns="columns"
        rowKey="uid"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        :scroll="{ x: 1000 }"
    >

      <template slot="status" slot-scope="text, record">
        <a-tag :color="record.status | dictName('yesNo', 'color')">{{
            record.status | dictName("yesNo")
          }}
        </a-tag>
      </template>
      <template slot="contentJson" slot-scope="text, record">
        <div v-for="one in Object.keys(text)" :key="one">
          <label>{{ one|dictName("rewardBriefItems") }}：</label>
          <span>{{ record.contentJson[one] }}</span>
        </div>
      </template>
      <template slot="operation" slot-scope="text, record">
        <a-icon
            type="edit"
            theme="twoTone"
            twoToneColor="#4a9ff5"
            @click="edit(record)"
            title="修改"
        ></a-icon>
        &nbsp;
      </template>
    </a-table>
    <reword-brief-form ref="rewardBriefForm" @success="search"/>
  </a-card>
</template>

<script>
import {ListMixin} from "@/mixin/list-mixin";
import RewordBriefForm from "./rewardBriefForm.vue";


export default {
  name: "RewardList",
  mixins: [ListMixin],
  components: {
    RewordBriefForm
  },
  data() {
    return {
      listGetUrl: "rewardBrief/listByAdmin",
      listMethod: "postJson"
    };
  },
  computed: {
    columns() {
      let {sortedInfo, filteredInfo} = this;
      sortedInfo = sortedInfo || {};
      filteredInfo = filteredInfo || {};
      return [
        {
          title: "ID",
          width: 80,
          align: "center",
          dataIndex: "id",
        },
        {
          title: "类型",
          align: "center",
          width: 100,
          dataIndex: "itemType"
        },
        {
          title: "名称",
          width: 200,
          align: "center",
          dataIndex: "itemName",
        },
        {
          title: "详情",
          width: 250,
          align: "center",
          dataIndex: "contentJson",
          scopedSlots: {customRender: "contentJson"},
        },
        {
          title: "是否有效",
          width: 100,
          align: "center",
          dataIndex: "status",
          scopedSlots: {customRender: "status"},
        },


        {
          title: "更新时间",
          width: 165,
          align: "center",
          dataIndex: "updateTime",
        },
        {
          title: "操作",
          width: 80,
          align: "center",
          dataIndex: "operation",
          // fixed: "right",
          scopedSlots: {customRender: "operation"},
        },
        {}
      ];
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    afterFetch(data) {
      data.records.forEach(v => {
        try {
          let contentJson = JSON.parse(v.itemDetail)

          v.contentJson = contentJson || {}
        } catch (e) {
          v.contentJson = {}
        }
      })
    },
    edit(row) {
      this.$refs.rewardBriefForm.showModal(row);
    },
  },
};
</script>

<style lang="less" scoped>
.textClick {
  color: #1890ff;
  cursor: pointer;
  text-decoration: underline;
}

.img-list {
  display: flex;
  justify-content: center;
}
</style>
